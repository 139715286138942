<template>
  <div>
    <div v-if="apiResponse || meterData" class="layout">
      <loading :active.sync="isLoading" :is-full-page="true"></loading>
      <Header
        @show-info-modal="showInfoModal($event)"
        :meter-name="meterName"
        :analytics="{ ref, zip }"
        :logo-url="getCompanyLogo()"
        :actual-kwh="actualKWHValue"
        :total-kwh-year="totalKWHExpectedForYear"
        :total-kwh-all-times="totalKWHForAllTimesValue"
        :operational-since="operationalSince"
        :selected-year="selectedYear"
        :amount-in-cents-per-kwh="amountInCentsPerKwh"
      />
      <div class="content">
        <div class="flex align-center items-center slider-wrapper">
          <div class="content-description">
            <h2 style="margin-bottom: -12px">De zon geeft energie op</h2>
            <h1>
              {{ address.street }}
              {{ address.street_number }}
              in
              {{ address.city }}
            </h1>
            <p class="font-thin">
              Elke maand maken we de balans op. Hoe hebben de zonnepanelen
              gepresteerd?
            </p>
          </div>
          <div class="content-cards">
            <swiper ref="mySwiper" :options="swiperOptions">
              <swiper-slide
                class="slide"
                v-for="card in cardsForActualYear"
                :key="card.id"
              >
                <div v-if="card.isCurrentMonth">
                  <CurrentMonthCard
                    :item="card"
                    :operational-since="operationalSince"
                  />
                </div>
                <div v-else>
                  <PreviousMonthCard
                    :item="card"
                    @show-info-modal="showInfoModal($event)"
                    :analytics="{ ref, zip }"
                    @show-weather-modal="showWeatherModal($event)"
                    :operational-since="operationalSince"
                  />
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination" slot="pagination"></div>
          </div>
        </div>
        <div>
          <div class="content-buttons-list hide-mobile">
            <button
              class="btn"
              @click="selectedYear = btn"
              :class="{ active: btn === selectedYear }"
              v-for="btn in buttons"
              :key="btn"
            >
              {{ btn }}
            </button>
          </div>
          <div class="content-buttons-list hide-desktop">
            <v-select
              :clearable="false"
              :options="buttons.map((btn) => btn)"
              class="app-selector content-buttons-select"
              :searchable="false"
              v-model="selectedYear"
            >
            </v-select>
          </div>
        </div>
      </div>
      <Footer />
    </div>
    <modal
      :adaptive="true"
      name="default"
      :height="'auto'"
      :max-width="378"
      :resizable="true"
      class="app-modal"
    >
      <div v-if="modal.title" class="modal-header">
        {{ modal.title }}
      </div>
      <div class="modal-content font-thin" v-html="modal.body" />
      <div class="modal-footer flex justify-center">
        <i
          @click="close"
          class="icon-icon_cross"
          style="width: 15px; cursor: pointer"
        ></i>
      </div>
    </modal>
    <ErrorApiRunModal />
    <NoParamsMessage />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import PreviousMonthCard from '@/components/PreviousMonthCard';
import ErrorApiRunModal from '@/components/ErrorApiRunModal';
import NoParamsMessage from '@/components/NoParamsMessage';
import SiriusApi from '@/data/SiriusApi';
import Session from '@/Session';
import CurrentMonthCard from '@/components/CurrentMonthCard';
import MonthCard from '../domain/MonthCard.js';

export default {
  name: 'App',
  components: {
    CurrentMonthCard,
    Header,
    Footer,
    PreviousMonthCard,
    NoParamsMessage,
    ErrorApiRunModal,
    Loading,
    // agile: VueAgile,
  },
  props: {
    meterData: {},
    weatherData: {},
  },
  data() {
    return {
      zip: null,
      ref: null,
      isLoading: false,
      client: null,
      apiIrradianceResponse: null,
      apiResponse: null,
      swiperOptions: {
        pagination: {
          clickable: true,
          el: '.swiper-pagination',
        },
        slidesPerView: 3,
        spaceBetween: 30,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
            centeredSlides: true,
          },
          900: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1260: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1680: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        },
      },
      selectedYear: new Date().getFullYear(),
      modal: {
        title: '',
        body: '',
      },
      options: {
        smallLogoUrl: '/img/logo.png',
      },
    };
  },
  computed: {
    meterName() {
      return this.apiResponse
        ? this.apiResponse.name +
            ' - ' +
            this.apiResponse.peak_in_watt +
            ' Watt'
        : null;
    },
    operationalSince() {
      return this.apiResponse ? this.apiResponse.operational_since : null;
    },
    totalKWHExpectedForYear() {
      return this.apiResponse
        ? Math.round(this.apiResponse.promised_total_in_wh / 1000)
        : null;
    },
    amountInCentsPerKwh() {
      return this.apiResponse ? this.apiResponse.amount_in_cents_per_kwh : null;
    },
    address() {
      return this.apiResponse
        ? this.apiResponse.plant.address
        : this.meterData.plant.address;
    },
    buttons() {
      return this.apiResponse
        ? this.apiResponse.performances
            .map((item) => {
              const date = new Date(item.start_date);
              return date.getFullYear();
            })
            .filter((value, index, self) => self.indexOf(value) === index)
        : [];
    },
    actualKWHValue() {
      return this.cardsForActualYear.reduce((val, item) => {
        return (val += item.actual_yield);
      }, 0);
    },
    totalKWHForAllTimesValue() {
      return this.cards.reduce((val, item) => {
        return (val += item.actual_yield);
      }, 0);
    },
    cards() {
      return this.apiResponse && this.apiIrradianceResponse
        ? this.apiResponse.performances
            .map((item, index) => {
              return new MonthCard(
                index,
                this.amountInCentsPerKwh,
                Math.round(this.apiResponse.promised_total_in_wh / 1000),
                item,
                this.apiIrradianceResponse
              );
            })
            .reverse()
        : [];
    },
    cardsForActualYear() {
      return this.cards.filter((item) => item.year === this.selectedYear);
    },
  },
  mounted() {
    this.zip = Session.postalCode;
    this.plantCode = Session.plantCode;
    this.meterId = Session.meterId;
    this.isLoading = true;

    const api = new SiriusApi();

    api
      .getMeterPlant(this.zip, this.plantCode, this.meterId)
      .then((response) => {
        this.apiResponse = response.data;

        api
          .getWeather(this.apiResponse.plant.weather_station_id)
          .then((response) => {
            this.apiIrradianceResponse = response.data;
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            this.$router.push({
              name: 'SignIn',
              params: { givenError: 'Het weer kon niet worden opgehaald' },
            });
          });
      })
      .catch(() => {
        this.isLoading = false;
        this.$router.push({
          name: 'SignIn',
          params: { givenError: 'Incorrecte code' },
        });
      });
  },
  methods: {
    showInfoModal(modalData) {
      this.modal = modalData;
      this.$modal.show('default');
    },
    showWeatherModal(card) {
      const data = {
        title: 'Het weer in ' + card.fullMonthName.toLowerCase(),
      };

      const sunValue = parseInt(
        parseFloat(card.monthWeather / card.weatherAvg) * 100
      );

      data.body = `De sterkte van de zon is vergeleken met afgelopen jaren. ${
        card.fullMonthName
      }
      had ${Math.abs(sunValue - 100)}% ${
        sunValue - 100 > 0 ? 'meer' : 'minder'
      } zon ten opzichte van het langjarig gemiddelde. `;

      this.modal = { ...this.modal, ...data };
      this.$modal.show('default');
    },

    close() {
      this.$modal.hide('default');
    },
  },
};
</script>

<style lang="scss">
@import './src/assets/scss/app.scss';
@import './src/assets/scss/variables.scss';

.swiper-container {
  height: 45vh;
  padding-left: 15px;
  @media screen and (max-width: 900px) {
    overflow: visible !important;
  }
}

.slider-wrapper {
  margin-bottom: 75px;
  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
}

.content-cards {
  position: relative;
  width: 65.5%;

  .swiper-pagination {
    background: var(--secondary-color);
    border-radius: 10px;
    padding: 2px 5px;
    position: absolute;
    left: 50%;
    bottom: -45px;
    transform: translateX(-50%);
    @media only screen and (max-width: 900px) {
      display: none;
    }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      margin: 0 5px;

      &:focus {
        outline: none;
      }
    }

    .swiper-pagination-bullet-active {
      background: var(--accent-color);
    }
  }

  @media screen and (max-width: 900px) {
    padding: 0 20px;
    width: 311px;
    margin: 0 auto;
  }
}

.content-buttons-list {
  text-align: center;
  width: 100%;

  .btn {
    margin: 0 5px;
  }
}

.content {
  width: 100%;
}

.content-buttons-select {
  margin: 0 auto;
  width: 310px;
}
</style>
